<template>
  <aside class="col-lg-4 col-xl-3 ml-xl-auto about-me-side-bar">
    <div class="sidebar">
      <div
        itemscope
        itemtype="http://schema.org/Organization"
        class="block clearfix"
      >
        <h3 class="title">İletişim</h3>
        <ul class="list-icons">
          <li itemprop="telephone">
            <i class="fa fa-mobile pr-10 text-default"></i>
            {{ me.phone }}
          </li>
          <li>
            <a :href="`mailto:${me.email}`" itemprop="email">
              <i class="fa fa-envelope-o pr-10"></i>
              {{ me.email }}
            </a>
          </li>
        </ul>
        <h3 class="mt-4">Linkler</h3>
        <div class="separator-2"></div>
        <ul class="social-links large circle animated-effect-1 colored">
          <link itemprop="url" href="https://www.julideirgin.com" />
          <li class="facebook">
            <a itemprop="sameAs" :href="me.facebook"
              ><i class="fa fa-facebook"></i
            ></a>
          </li>
          <li class="linkedin">
            <a itemprop="sameAs" :href="me.linkedin"
              ><i class="fa fa-linkedin"></i
            ></a>
          </li>
          <li>
            <a
              itemprop="sameAs"
              class="armut"
              title="Armut.com"
              :href="me.armutcom"
              ><i class="fa fa-armut"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    me: {
      type: Object,
      reqired: true,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      armutLogo: require("../assets/white_armut_1@6x-8.png")
    };
  }
};
</script>
