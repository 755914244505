<template>
  <div
    class="main col-lg-8 about-me-content"
    itemscope
    itemtype="http://schema.org/Person"
  >
    <h1 class="title page-title" itemprop="name">{{ me.name }}</h1>
    <div class="separator-2"></div>
    <h2 class="title" itemprop="jobTitle">
      {{ me.title }}
    </h2>
    <div class="separator-2"></div>
    <p v-for="(item, index) in me.desc" :key="index" itemprop="description">
      {{ item }}
    </p>
    <h2 class="title">
      Eğitimler
    </h2>
    <div class="separator-2"></div>
    <ul>
      <li
        v-for="(item, index) in me.trainings"
        :key="index"
        itemprop="knowsAbout"
      >
        {{ item }}
      </li>
    </ul>
    <h2 class="title">
      Test Eğitimleri
    </h2>
    <div class="separator-2"></div>
    <ul>
      <li
        v-for="(item, index) in me.testTrainings"
        :key="index"
        itemprop="knowsAbout"
      >
        {{ item }}
      </li>
    </ul>
    <h2 class="title">
      Üye Olunan Dernekler
    </h2>
    <div class="separator-2"></div>
    <ul>
      <li
        v-for="(item, index) in me.associations"
        :key="index"
        itemprop="memberOf"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    me: {
      type: Object,
      reqired: true,
      default: function() {
        return {};
      }
    }
  }
};
</script>
