<template>
  <section class="main-container">
    <div class="container">
      <div class="row">
        <about-me-content :me="me" />
        <about-me-side-bar :me="me" />
      </div>
    </div>
  </section>
</template>

<script>
import AboutMeContent from "../components/AboutMeContent.vue";
import AboutMeSideBar from "../components/AboutMeSideBar.vue";
import { data } from "../data/data.js";

const me = data.aboutMe;

export default {
  components: { AboutMeContent, AboutMeSideBar },
  metaInfo: {
    title: "Hakkımda"
  },
  data() {
    return {
      me: me
    };
  }
};
</script>
